import { 
  useEffect, 
  useState,
} from "react";
import styled from 'styled-components';
import { 
  Button, 
  // Fab, 
  FormControl, 
  Grid, 
  IconButton,
  InputLabel, 
  MenuItem, 
  Paper, 
  Select, 
  TextField, 
  Tooltip 
} from "@material-ui/core";
import { 
  InfoOutlined,
  // Add,
  DeleteOutline,
 } from "@material-ui/icons";
import TaxBrackets from './constants';

const Preformatted = styled.pre`
  padding: 50px;
  padding-right: 0;
`;

const Container = styled(Grid)`
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Item = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemWithTooltip = styled(Item)`
  position: relative;
`;

const LineWrapper = styled.div`

`;

// const StyledFab = styled(Fab)`
//  position: absolute;
//  right: 10px;
//  bottom: 10px;
// `;

const StyledItem = styled(Item)`
  padding: 20px 0;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  left: calc(50% + 135px);
`;

function App() {
  const [ state, setState ] = useState({
    fiscalYear: 2079,
    month: 12,
    maritalStatus: "unmarried",
    gender: "male",
    totalIncome: "",
    basicIncome: "",
    cit: "",
    lifeInsurance: "",
    buildingInsurance: "",
    onetimeAllowances: [],
    taxBreakdownPersist: null,
  });
  
  const {
    basicIncome,
    buildingInsurance,
    cit,
    fiscalYear,
    gender,
    lifeInsurance,
    onetimeAllowances,
    maritalStatus,
    month,
    taxBreakdownPersist,
    totalIncome,
  } = state;
  
  useEffect(() => {
    setState(state => ({
      ...state,
      taxBreakdownPersist: null,
    }))
  }, [
    basicIncome,
    buildingInsurance,
    cit,
    fiscalYear,
    gender,
    lifeInsurance,
    maritalStatus,
    month,
    totalIncome,
  ]);

  const annualTotalIncome = month * totalIncome;
  const annualBasicIncome = month * basicIncome;

  // CIT
  const maxCitAnnualLimit = TaxBrackets[`tax${fiscalYear}`].benefitLimits.cit;
  const citAnnual = month * cit;
  const citCondition = citAnnual > maxCitAnnualLimit;
  const untaxedCit = citCondition ? maxCitAnnualLimit : citAnnual;

  const ssf = basicIncome * 0.31;
  const ssfAnnual = ssf * month;
  const taxBracketMeta = TaxBrackets[`tax${fiscalYear}`];
  const recommendedCIT = Math.min(annualTotalIncome / 3, taxBracketMeta.benefitLimits.cumulativeSsfCit) - ssfAnnual;

  const calculate = () => {
    const lifeInsuranceBenefit = Math.min(lifeInsurance || 0, taxBracketMeta.benefitLimits.lifeInsurance);
    const buildingInsuranceBenefit = Math.min(buildingInsurance || 0, taxBracketMeta.benefitLimits.buildingInsurance);

    const taxBracket = taxBracketMeta[maritalStatus];
    
    const minimum = Math.min(annualTotalIncome / 3, untaxedCit + ssfAnnual, taxBracketMeta.benefitLimits.cumulativeSsfCit);
    const taxable = annualTotalIncome - minimum - lifeInsuranceBenefit - buildingInsuranceBenefit;
    const taxBreakdown = [ ...taxBracket ];

    console.log('here',buildingInsurance)

    taxBracket.reduce((acc, tax, index) => {
      if(tax.amount) {
        if( tax.amount <= acc ) {
          const taxed = tax.amount * tax.multiplier;
          
          taxBreakdown[index].taxed = taxed;
          taxBreakdown[index].payable = tax.amount - taxed;
          
          return acc - tax.amount; 
        }
      }

      const taxed = acc * tax.multiplier;

      taxBreakdown[index].payable = acc - taxed;
      taxBreakdown[index].taxed = taxed;
      
      return 0;
    }, taxable);

    setState(state => ({
      ...state,
      taxBreakdownPersist: taxBreakdown,
    }));
  };

  const renderCalculations = () => {
    const precision = 2;
    const netPayable = (annualTotalIncome - ssfAnnual - untaxedCit);
    const tds = taxBreakdownPersist?.reduce((acc, bracket) => acc + bracket.taxed, 0);

    const taxBracketFiltered= taxBreakdownPersist?.filter((bracket) => bracket.payable);

    return <Paper elevation={3}>
      <Preformatted>
        <LineWrapper>
          1/3<sup>rd</sup> of Annual Income: {(annualTotalIncome / 3).toFixed(precision)}
        </LineWrapper>
        <LineWrapper>
          Tax Benefit for SSF + CIT: {(untaxedCit + ssfAnnual).toFixed(precision)}
        </LineWrapper>
        <LineWrapper>
          Max Tax Benefit for SSF + CIT: {taxBracketMeta.benefitLimits.cumulativeSsfCit.toFixed(precision)}
        </LineWrapper>
        <br />
        <LineWrapper>
          Minimum of above 3: {Math.min(...[annualTotalIncome / 3, untaxedCit + ssfAnnual, taxBracketMeta.benefitLimits.cumulativeSsfCit]).toFixed(precision)}
        </LineWrapper>

        {taxBracketFiltered.map((bracket, index) => <>
          <br />
          ========================================
          <br />
          <br />
          <LineWrapper>
            Bracket: {bracket.amount.toFixed(precision) || 0}
          </LineWrapper>
          <LineWrapper>
            Percentage Taxed: {bracket.multiplier * 100 || 0}%
          </LineWrapper>
          <LineWrapper>
            Before Taxes: {(bracket.payable + bracket.taxed).toFixed(precision) || 0}
          </LineWrapper>
          {
            index === taxBracketFiltered.length-1 && lifeInsurance && 
            (<LineWrapper>
              Life Insurance: {(Number(lifeInsurance)).toFixed(precision)}
            </LineWrapper>)
          }
          {
            index === taxBracketFiltered.length-1 && buildingInsurance && 
            (<LineWrapper>
              Building Insurance: {(Number(buildingInsurance)).toFixed(precision)}
            </LineWrapper>)
          }
          <LineWrapper>
            Taxed: {bracket.taxed.toFixed(precision) || '0'}
          </LineWrapper>
          <LineWrapper>
            Payable: {bracket.payable.toFixed(precision) || '0'}
          </LineWrapper>
        </>)}
        <br />
          ========================================
        <br />
        <br />
        <LineWrapper>
          Total Annual Income: {annualTotalIncome.toFixed(precision)}
        </LineWrapper>
        <LineWrapper>
          Annual SSF: {ssfAnnual.toFixed(precision)}
        </LineWrapper>
        <LineWrapper>
          Annual CIT: {citAnnual.toFixed(precision)}
        </LineWrapper>
        <LineWrapper>
          Annual Net Payable: {netPayable.toFixed(precision)}
        </LineWrapper>
        <LineWrapper>
          Annual TDS: {tds.toFixed(precision)}
        </LineWrapper>
        {gender === 'female' && <LineWrapper>
          Annual Tax Rebate: {(tds * 0.1).toFixed(precision)}
        </LineWrapper>}
        <LineWrapper>
          Annual Cash in hand: {(netPayable - (gender === 'female' ? tds * 0.9 : tds)).toFixed(precision)}
        </LineWrapper>
        <br />
        ========================================
        <br />
        <br />
        <LineWrapper>
          Total Monthly Income: {totalIncome || 0}
        </LineWrapper>
        <LineWrapper>
          Monthly SSF: {ssf || 0}
        </LineWrapper>
        <LineWrapper>
          Monthly CIT: {cit || 0}
        </LineWrapper>
        <LineWrapper>
          Monthly Net Payable: {(netPayable / month).toFixed(precision)}
        </LineWrapper>
        <LineWrapper>
          Monthly TDS: {(tds / month).toFixed(precision)}
        </LineWrapper>
        {gender === 'female' && <LineWrapper>
          Monthly Tax Rebate: {((tds / month) * 0.1).toFixed(precision)}
        </LineWrapper>}
        <LineWrapper>
          Monthly Cash in hand: {((netPayable / month) - (gender === 'female' ? (tds * 0.9) / month : tds / month)).toFixed(precision)}
        </LineWrapper>
      </Preformatted>
    </Paper>
  };

  return (
    <div style={{ minHeight: '100vh'}}>
      <Container container spacing={3}>
        <Item xs={12} md={6}>
          <FormControl variant="outlined">
            <InputLabel id="fiscal_year">Fiscal Year</InputLabel>
            <Select
              labelId="fiscal_year"
              value={fiscalYear}
              onChange={(e) => setState(state => ({
                ...state,
                fiscalYear: e.target.value
              }))}
              label="Fiscal Year"
            >
              <MenuItem value={2078}>2078 - 2079</MenuItem>
              <MenuItem value={2079}>2079 - 2080</MenuItem>
            </Select>
          </FormControl>
        </Item>
        <Item xs={12} md={6}>
          <FormControl variant="outlined">
            <InputLabel id="month">Month</InputLabel>
            <Select
              labelId="month"
              value={month}
              onChange={(e) => setState(state => ({
                ...state,
                month: e.target.value
              }))}
              label="Month"
            >
              <MenuItem value={12}>Shrawan</MenuItem>
              <MenuItem value={11}>Bhadra</MenuItem>
              <MenuItem value={10}>Ashwin</MenuItem>
              <MenuItem value={9}>Kartik</MenuItem>
              <MenuItem value={8}>Mangsir</MenuItem>
              <MenuItem value={7}>Poush</MenuItem>
              <MenuItem value={6}>Magh</MenuItem>
              <MenuItem value={5}>Falgun</MenuItem>
              <MenuItem value={4}>Chaitra</MenuItem>
              <MenuItem value={3}>Baisakh</MenuItem>
              <MenuItem value={2}>Jestha</MenuItem>
              <MenuItem value={1}>Ashadh</MenuItem>
            </Select>
          </FormControl>
        </Item>
        <StyledItem xs={12} md={6}>
          <FormControl variant="outlined">
            <InputLabel id="marital_status">Marital Status</InputLabel>
            <Select
              labelId="marital_status"
              value={maritalStatus}
              onChange={(e) => setState(state => ({
                ...state,
                maritalStatus: e.target.value
              }))}
              label="Marital Status"
            >
              <MenuItem value={"married"}>Married</MenuItem>
              <MenuItem value={"unmarried"}>Unmarried</MenuItem>
            </Select>
          </FormControl>
        </StyledItem>
        <StyledItem xs={12} md={6}>
          <FormControl variant='outlined'>
            <InputLabel id="gender">Gender</InputLabel>
            <Select
              labelId='gender'
              value={gender}
              onChange={(e) => setState(state => ({
                ...state,
                gender: e.target.value
              }))}
              label="Gender"
            >
              <MenuItem value='male'>Male</MenuItem>
              <MenuItem value='female'>Female</MenuItem>
            </Select>
          </FormControl>
        </StyledItem>
        <Item item xs={12} md={6}>
          <TextField 
            label='Total Income (Monthly)'
            variant='outlined'
            type="number"
            value={totalIncome} 
            onChange={e => setState(state => ({
              ...state,
              totalIncome: e.target.value
            }))}
          />
        </Item>
        <Item item xs={12} md={6}>
          <Tooltip title="This is calculated automatically as monthly" arrow>
            <TextField 
              label='Annual Total Income'
              variant='outlined'
              disabled
              type='number'
              value={annualTotalIncome}
            />
          </Tooltip>
        </Item>
        <Item item xs={12} md={6}>
          <TextField 
            label='Basic Income (Monthly)'
            variant='outlined'
            type='number'
            value={basicIncome}
            onChange={e => setState(state => ({
              ...state,
              basicIncome: e.target.value,
            }))}
          />
        </Item>
        <Item item xs={12} md={6}>
          <Tooltip title='This is calculated automatically as monthly' arrow>
            <TextField 
              label='Annual Basic Income'
              variant='outlined'
              disabled
              type='number'
              value={annualBasicIncome} 
            />
          </Tooltip>
        </Item>
        {onetimeAllowances.map((allowance, index) => <Item item xs={12}>
          <TextField 
            label='One-time allowance' 
            variant='outlined' 
            type='number'
            value={allowance}
            onChange={e => setState(state => ({
              ...state,
              onetimeAllowances: state.onetimeAllowances.map((allowance, newIndex) => newIndex === index ? e.target.value : allowance),
            }))}
          />
            <IconButton 
              color='secondary'
              aria-label='delete'
              onClick={() => setState(state => ({
                ...state,
                onetimeAllowances: state.onetimeAllowances.filter((allowance, filterIndex) => filterIndex !== index),
              }))}
            >
              <DeleteOutline />
            </IconButton>
          </Item>
        )}
        <Item item xs={12} md={6}>
          <Tooltip title='SSF is 31% of Basic Income' arrow>
            <TextField 
              label='SSF (Monthly)'
              variant='outlined'
              type='number'
              value={ssf}
              disabled/>
          </Tooltip>
        </Item>
        <Item item xs={12} md={6}>
          <Tooltip title='SSF is 31% of Basic Income' arrow>
            <TextField 
              label='Annual SSF'
              variant='outlined'
              disabled
              type='number'
              value={ssfAnnual}
            />
          </Tooltip>
        </Item>
        <ItemWithTooltip item xs={12} md={6}>
          <TextField 
            label='CIT (Monthly)'
            variant='outlined'
            type='number'
            value={cit}
            onChange={e => setState(state => ({
              ...state,
              cit: e.target.value,
            }))}
          />
          {totalIncome && <StyledTooltip title={`Recommended Minium CIT For Maximum Tax Benefit: ${recommendedCIT.toFixed(2)} Annual / ${(recommendedCIT / 12).toFixed(2)} Monthly`} aria-label="info">
            <InfoOutlined />
          </StyledTooltip>}
        </ItemWithTooltip>
        <Item item xs={12} md={6}>
          <Tooltip title='This is calculated automatically as monthly' arrow>
            <TextField 
              label='Annual CIT'
              variant='outlined'
              type='number'
              value={citAnnual}
              disabled 
            />
          </Tooltip>
        </Item>
        <ItemWithTooltip item xs={12} md={6}>
          <TextField 
            label='Life Insurance'
            variant='outlined'
            type='number'
            value={lifeInsurance}
            onChange={e => setState(state => ({
              ...state,
              lifeInsurance: e.target.value,
            }))}
          />
          <StyledTooltip title={`Maximum Benefit from Life Insurance: ${taxBracketMeta.benefitLimits.lifeInsurance}`} aria-label="info">
            <InfoOutlined />
          </StyledTooltip>
        </ItemWithTooltip>
        <ItemWithTooltip item xs={12} md={6}>
          <TextField 
            label='Building Insurance'
            variant='outlined'
            type='number'
            value={buildingInsurance}
            onChange={e => setState(state => ({
              ...state,
              buildingInsurance: e.target.value,
            }))}
          />
          <StyledTooltip title={`Maximum Benefit from Building Insurance: ${taxBracketMeta.benefitLimits.buildingInsurance}`} aria-label="info">
            <InfoOutlined />
          </StyledTooltip>
        </ItemWithTooltip>
        <Item item xs={12} md={12}>
          <Button variant="contained" color="primary" onClick={calculate}>Calculate</Button>
        </Item>
        {taxBreakdownPersist && <Grid item xs={12} md={6}>
          {renderCalculations()}
        </Grid>}
        {/* <StyledFab 
          variant="extended"
          color="secondary"
          size="small"
          aria-label="add"
          onClick={() => setState(state => ({
            ...state,
            onetimeAllowances: [
              ...state.onetimeAllowances,
              '',
            ],
          }))}
        >
          <Add />
          Add One-Time Allowance
        </StyledFab> */}
      </Container>
    </div>
  );
}

export default App;
