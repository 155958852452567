const brackets = {
  tax2078: {
    unmarried: [{
      amount: 400000,
      multiplier: 0,
    },
    {
      amount: 100000,
      multiplier: 0.1,
    },
    {
      amount: 200000,
      multiplier: 0.2,
    },
    {
      amount: 1300000,
      multiplier: 0.3,
    },
    {
      multiplier: 0.36,
    }],
    married: [{
      amount: 450000,
      multiplier: 0,
    },
    {
      amount: 100000,
      multiplier: 0.1,
    },
    {
      amount: 200000,
      multiplier: 0.2,
    },
    {
      amount: 1250000,
      multiplier: 0.3,
    },
    {
      multiplier: 0.36,
    }],
    benefitLimits: {
      cit: 300000,
      cumulativeSsfCit: 400000,
      lifeInsurance: 40000,
      buildingInsurance: 5000,
    },
  },
  tax2079: {
    unmarried: [{
      amount: 500000,
      multiplier: 0,
    },
    {
      amount: 200000,
      multiplier: 0.1,
    },
    {
      amount: 300000,
      multiplier: 0.2,
    },
    {
      amount: 1000000,
      multiplier: 0.3,
    },
    {
      multiplier: 0.36,
    }],
    married: [{
      amount: 600000,
      multiplier: 0,
    },
    {
      amount: 200000,
      multiplier: 0.1,
    },
    {
      amount: 300000,
      multiplier: 0.2,
    },
    {
      amount: 1000000,
      multiplier: 0.3,
    },
    {
      multiplier: 0.36,
    }],
    benefitLimits: {
      cit: 300000,
      cumulativeSsfCit: 500000,
      lifeInsurance: 40000,
      buildingInsurance: 5000,
    },
  }
};

export default brackets;